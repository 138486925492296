.c-logo{
    width: 150px;
    height: 150px;
    object-fit: contain;
}


.c-logo--full{
    width: 100%;
    height: auto;
    display: block;
    border-radius: 0;
}