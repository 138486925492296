.c-text{
    display: flex;
    @include media-breakpoint-up(md) { 
        &:before{
            content: '';
            width: 112px;
            min-width: 112px;
            height: 2px;
            background-color: $black;
            display: inline-block;
            margin-right: 2rem;
            margin-top: 0.8rem;
        }
     }
}