.c-images {
    display: grid;
    gap: 20px;
    @include media-breakpoint-up(md) {
        grid-template-columns: 0.73fr 1fr 0.73fr;
    }

    img {
        width: 100%;
        height: auto;
    }
}
