.c-button{
    padding: 1rem 2.5rem;
    border-radius: 30px;
    display: inline-block;
    width: auto;
    &--primary{
        background-color: $primary;
        color: $white;
        text-decoration: none;
        font-family: $base-font-family;
        transition: all 0.3s ease-out;
        border: 2px solid $primary;
        &:hover{
            background-color: transparent;
            color: $primary;
        }
    }
}