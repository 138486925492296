// FONTS

@include fontFace('Roboto Mono','../fonts/roboto-mono/roboto-mono-regular', 400);
@include fontFace('Roboto Mono','../fonts/roboto-mono/roboto-mono-600', 600);

@include fontFace('Roboto','../fonts/roboto/roboto-regular', 400);
@include fontFace('Roboto','../fonts/roboto/roboto-700', 700);

$base-font-family: 'Roboto Mono', sans-serif;
$accent-font-family: 'Roboto', sans-serif;
$title-font-family: 'snug-variable', sans-serif;

// COLORS
$primary: #4618F9;

$gray: #E5E5E5;
$black: #000000;

$border-radius: 15px;