.c-footer {
    @include media-breakpoint-up(md) { 
    margin-top: -3rem;
    }
    @include media-breakpoint-up(lg) { 
    margin-top: -10rem;
    }
    @include media-breakpoint-up(xxl) { 
    margin-top: -20rem;
    }
    z-index: 0;

    &__inner {
        background-color: $gray;
    }

    &__top {
        width: 100%;
        height: auto;
    }

    &__logo {
        width: 76px;
        height: 90px;
    }
    &__sub{
        padding: 1rem 0;
        border-top: 2px solid $black;
        color: $primary;
        font-size: 0.75rem;
        a{
            text-decoration: none;
            color: $primary;
        }
    }

    a{
        color: $black;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}
