.c-timeline {
    .col {
        position: relative;
        div {
            margin-left: 1rem;
            width: calc(100% - 40px);
        }
        &:first-child {
            &:before {
                height: 50%;
                top: 50%;
            }
        }
        &:last-child {
            &:before {
                height: 50%;
                bottom: 50%;
            }
        }
        &:before {
            content: "";
            height: 100%;
            width: 2px;
            background-color: $black;
            display: block;
            position: absolute;
            top: 0;
            left: 20px;
            @include media-breakpoint-up(md) {
                width: 100%;
                height: 2px;
                left: initial;
                top: 10px;
            }
        }
       
        @include media-breakpoint-up(md) {
            &:first-child {
                &:before {
                    width: 50%;
                    left: 50%;
                    top: 10px;
                    height: 2px;
                }
            }
            &:last-child {
                &:before {
                    width: 50%;
                    left: 0;
                    height: 2px;
                }
            }
        }
    }
    span {
        width: 20px;
        height: 20px;
        background-color: $primary;
        border-radius: 50%;
        display: flex;
        align-items: center;
        z-index: 5;
    }

    h3 {
        margin-top: 2rem;
    }
}
