.c-hero {
    position: relative;
    margin: 0 auto;
    width: 100%;

    display: flex;
    flex-wrap: wrap;

    top: -5.2rem;

    @include media-breakpoint-up(md) {
        top: -6.8rem;
    }

    .container {
        position: relative;
    }

    &__maxi {
        width: 100%;
        height: auto;
    }
    &__mini {
        margin: -6rem auto 0 auto;
        width: 150px;
        min-width: 150px;


        @include media-breakpoint-up(xl) {
            margin-right: 1.5rem;
            margin-top: 0;
            .glide__bullets{
                display: inline-flex;
            }
        }
        @include media-breakpoint-up(xxl) {
            width: 200px;
            min-width: 200px;
        }

        @media screen and (min-width: 1700px) {
            width: 258px;
            min-width: 258px;
        }

        img {
            border-radius: $border-radius;
        }
    }
    &__slider {
        max-width: 100%;
        position: relative;
        img {
            border-radius: 0;
        }

        .glide__bullets{
            display: none;
        }

        @include media-breakpoint-up(xl) {

            .glide__bullets{
                bottom: 4em;
                display: inline-flex;
            }
        }

        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 400px;
            background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjYuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMDAwIDIwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjAwMCAyMDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMCw2Ni44VjIwMGgyMDAwVjY2LjhDMjAwMCw2Ni44LDExNjcuMSwzMjEuNSwwLDY2Ljh6Ii8+CjwvZz4KPC9zdmc+Cg==")
                center bottom no-repeat;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &__content {
        z-index: 5;
        padding: 2rem 0;
        width: 100%;

        h1{
            margin-top: 2rem;
        }

        .c-text {
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        @include media-breakpoint-up(xl) {
            position: absolute;
            top: 20%;
            right: 0;
            width: 775px;

            h1{
                max-width: 400px;
                margin-top: 0;
            }

            .c-text{
                div{
                    display: block;
                }
            }
        }

        @include media-breakpoint-up(xxl) {
            right: 10%;
        }
        @media screen and (min-width: 1700px) {
            right: 15%;
        }
        @media screen and (min-width: 2000px) {
            right: 25%;
        }
    }
}
