.body-overflow {
    height: 100%;
    overflow: hidden;

    .c-nav {
        visibility: visible;
        opacity: 1;
        z-index: 999;
        &__toggle {
            span {
                background-color: $white;
                &:first-child {
                    top: 18px;
                    width: 0;
                    left: 50%;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
                &:nth-child(4) {
                    top: 18px;
                    width: 0;
                    left: 50%;
                }
            }
        }
    }
}

.c-nav {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: $primary url("../images/nav-bg.jpg") center center no-repeat;
    background-size: cover;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    transition: all 0.5s ease-out;

    &__logo{
        width: 150px;
        height: auto;
        @include media-breakpoint-up(md) { 
            width: 260px;
        height: auto;
        }
    }

    &__toggle {
        width: 30px;
        height: 25px;
        position: relative;
        transition: 0.5s ease-in-out;
        z-index: 11;
        cursor: pointer;
        span {
            display: block;
            position: absolute;
            height: 4px;
            width: 100%;
            background-color: $black;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transition: 0.25s ease-in-out;
            &:first-child {
                top: 0;
            }
            &:nth-child(2),
            &:nth-child(3) {
                top: 10px;
            }
            &:nth-child(4) {
                top: 20px;
            }
        }
    }
    ul {
        list-style-type: none;
        margin-top: 3rem;
        @include media-breakpoint-up(md) { 
            margin-top: 5rem;
        }
        li {
            text-align: right;
            &:not(:first-of-type) {
                margin-top: 1rem;
            }
            a {
                color: $white;
                text-align: right;
                text-decoration: none;
                font-size: 2.5rem;
                @include media-breakpoint-up(md) {
                    font-size: 4rem;
                }
                font-family: $accent-font-family;
                text-transform: uppercase;
                font-weight: bold;
                border-bottom: 2px solid transparent;
                padding-bottom: 0.4rem;
                transition: all 0.3s ease-out;
                &:hover,
                &.active {
                    border-color: $white;
                }
            }
        }
    }
}
