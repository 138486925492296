$spacing: (
    6: '6.5rem',
    7: '10rem'
);

.mt {
    @each $m, $val in map-keys($spacing) {
        &-#{$m} {
            margin-top: #{map-get($spacing, $m)} !important;
        }
    }

    @each $bp in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($bp) {
            @each $m, $val in map-keys($spacing) {
                &-#{$bp}-#{$m} {
                    margin-top: #{map-get($spacing, $m)} !important;
                }
            }
        }
    }
}