.c-list{
    margin: 0;
    padding: 0;
    li{
        list-style-type: none;
        color: $primary;
        font-size: 1.5rem;
        font-family: $base-font-family;
        font-weight: bold;
        &:not(:first-of-type){
            margin-top: 1rem;
        }
        &:before{
            content: '✓';
            display: inline-block;
            margin-right: 2rem;
        }
    }
}