// VENDOR
@import 'vendor/bootstrap/bootstrap-grid.scss';
@import 'vendor/slick/slick';
@import 'vendor/slick/slick-theme';
@import 'vendor/glider';
@import 'vendor/glide/glide.core.scss';
@import 'vendor/glide/glide.theme.scss';

// TOOLS
@import 'tools/toolx.mixins';

// SETTINGS
@import 'settings';

// GENERAL
@import 'generals/general.base';


// UTILITIES
@import 'utilities/utilities.backgrounds';
@import 'utilities/utilities.rounded';
@import 'utilities/utilities.spacing';


// COMPONENTS
@import 'components/components.buttons';
@import 'components/components.footer';
@import 'components/components.header';
@import 'components/components.hero';
@import 'components/components.icons';
@import 'components/components.images';
@import 'components/components.lists';
@import 'components/components.logos';
@import 'components/components.navigation';
@import 'components/components.slider';
@import 'components/components.testimonials';
@import 'components/components.text';
@import 'components/components.timeline';


.container{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}