html {
    font-family: $base-font-family;
    font-size: 16px;
}

body {
    font-family: $base-font-family;
    font-size: 16px;
}

p {
    font-family: $base-font-family;
    span{
        color: $primary;
        font-weight: bold;
    }
}
footer {
    font-size: 14px;
}



main {
    position: relative;
    z-index: 5;
    h1 {
        font-family: $title-font-family;
        font-size: 3.5rem;
        @include media-breakpoint-up(md) { 
            font-size: 5rem;
        }
        line-height: 1;
        text-transform: uppercase;
        span {
            color: $primary;
        }
    }

    h2 {
        font-family: $title-font-family;
        font-size: 3.5rem;
        @include media-breakpoint-up(md) { 
            font-size: 5rem;
        }
        line-height: 1;
        text-transform: uppercase;
        span {
            color: $primary;
        }
        &.text__line{
            &:after{
                content: '';
                width: 112px;
                height:2px;
                background-color: $black;
                display: block;
                margin-top: 1rem;
            }
        }
    }

    h3{
        font-family: $accent-font-family;
        font-size: 1.5rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        border-radius: $border-radius;
    }
}
